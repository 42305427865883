import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import useAuth from '../../hooks/useAuth';

const CollectionList = ({ isOpen, collections, onCollectionClick, chosenCollectionPath }) => {
  const { isAdmin } = useAuth();

  const variants = {
    open: { height: 'auto' },
    closed: { height: 0 },
  };

  return (
    <motion.ul animate={isOpen ? 'open' : 'closed'} variants={variants}>
      {collections.map(({ name, path, articles }) => {
        const isPublic = articles.some(({ isPublic }) => isPublic);

        return (
          <li
            key={name}
            className={cn('mt-2 pl-5 font-medium cursor-pointer', {
              'bg-purple-100': path === chosenCollectionPath,
              'opacity-70': !isPublic && !isAdmin,
            })}
            onClick={() => onCollectionClick(path)}
          >
            {name}
          </li>
        );
      })}
    </motion.ul>
  );
};

export default CollectionList;
