import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import cn from 'classnames';

import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';
import { ArticlesContext } from '../../context/Articles';

const ArticleList = ({ articles, chosenArticlePath }) => {
  const { isMobile, isMobileStructureOpen, toggleMobileStructure } = useResponsive();
  const { isAdmin } = useAuth();
  const { pathname } = useLocation();
  const { saveScrollPosition } = useContext(ArticlesContext);

  const handleListClick = () => {
    if (isMobileStructureOpen) {
      return;
    }

    toggleMobileStructure(true);
  };

  const handleListItemClick = (event) => {
    if (isMobile && !isMobileStructureOpen) {
      event.preventDefault();

      return;
    }

    saveScrollPosition();
    toggleMobileStructure(false);
  };

  return (
    <ul
      className="grow shrink-0 flex flex-col md:w-60 border-purple-primary border-r-2 overflow-auto"
      onClick={handleListClick}
    >
      {articles.map(({ title, path, isPublic }) => (
        <li
          key={path}
          className={cn('shrink-0 font-bold border-b border-purple-primary', {
            'bg-purple-100': path.toLowerCase() === chosenArticlePath.toLowerCase(),
            'opacity-60': !isPublic && !isAdmin,
          })}
        >
          <Link
            className="block p-4 hover:no-underline"
            to={`/compendium/${path}`}
            onClick={handleListItemClick}
          >
            {title}
          </Link>
        </li>
      ))}
      {process.env.GATSBY_ENV === 'development' && (
        <Link
          className="mt-auto p-4 text-xs text-end"
          to={`/compendium/${pathname.split('/compendium/')[1].toUpperCase()}`}
        >
          Get dynamic article
        </Link>
      )}
    </ul>
  );
};

export default ArticleList;
