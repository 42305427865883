import React, { useState, createContext, useRef } from 'react';
import { useLocation } from '@reach/router';

export const ArticlesContext = createContext();

const ArticlesProvider = ({ children }) => {
  const scrollPositions = useRef({});
  const { key } = useLocation();
  const [currentArticle, setCurrentArticle] = useState({});

  // TODO: if this solution becomes hard to maintain, consider rewriting to onPreRouteUpdate.
  // but in such case @reach/router routes should have separate handling
  //
  // also, test whether window.scrollY works in useEffect cleanup function (most probably no)
  function saveScrollPosition() {
    const articleEl = document.getElementById('article');

    if (articleEl) {
      scrollPositions.current[key] = articleEl.scrollTop;
    }
  }

  const value = {
    scrollPositions,
    saveScrollPosition,
    currentArticle,
    setCurrentArticle,
  };

  return <ArticlesContext.Provider value={value}>{children}</ArticlesContext.Provider>;
};

export default ArticlesProvider;
