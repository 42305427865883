import React from 'react';

import App from './src/components/App';
import CompendiumLayout from './src/components/CompendiumLayout';
import ArticlesProvider from './src/context/Articles';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};

export const wrapPageElement = ({ element, props }) => {
  if (
    props.location.pathname.startsWith('/compendium/') &&
    !props.location.pathname.startsWith('/compendium/about/')
  ) {
    return (
      <ArticlesProvider>
        <CompendiumLayout pathname={props.location.pathname}>{element}</CompendiumLayout>;
      </ArticlesProvider>
    );
  }

  return element;
};
