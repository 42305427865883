export const replaceSpacesWithUnderscores = (value) => value.replace(/ /g, '_');

export const replaceUnderscoresWithSpaces = (value) => value.replace(/_/g, ' ');

export function removeExtension(value) {
  return value.replace(/\..+$/, '');
}

export function removeTrailingSlash(path) {
  return path.replace(/\/$/, '');
}
