import React, { useState, useEffect, useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';

import ArticleList from '../ArticleList';
import CompendiumStructure from '../CompendiumStructure';
import useResponsive from '../../hooks/useResponsive';
import { removeTrailingSlash } from '../../utils/regex';
import { ArticlesContext } from '../../context/Articles';

const prepareStructure = (value) =>
  value.map((item) => ({
    ...item,
    isOpen: false,
  }));

const compendiumMetadataQuery = graphql`
  query {
    compendium {
      metadata {
        structure {
          name
          path
          collections {
            name
            path
            articles {
              title
              path
              isPublic
            }
          }
        }
      }
    }
  }
`;

const retrieveCollectionFromStructure = (structure, rootCollection, collection) => {
  const rootCollectionObject = structure.find(
    (structureRootCollection) =>
      structureRootCollection.path === `${rootCollection}/`.toLowerCase(),
  );

  if (!rootCollectionObject) {
    throw new Error('Unexisting root collection');
  }

  const collectionObject = rootCollectionObject.collections.find(
    (structureCollection) =>
      structureCollection.path === `${rootCollection}/${collection}/`.toLowerCase(),
  );

  if (!collectionObject) {
    throw new Error('Unexisting collection');
  }

  return collectionObject;
};

const getInitialChosenCollection = (...args) => {
  const emptyCollection = {
    articles: [],
    name: null,
    path: null,
  };

  try {
    return retrieveCollectionFromStructure(...args);
  } catch {
    return emptyCollection;
  }
};

const CompendiumLayout = ({ children, pathname }) => {
  const data = useStaticQuery(compendiumMetadataQuery);
  const [structure, setStructure] = useState(prepareStructure(data.compendium.metadata.structure));
  const { isMobileStructureOpen } = useResponsive();
  const [rootCollection, collection, article, ...extraSegments] = removeTrailingSlash(pathname)
    .match(/\/compendium\/?(.*)$/)[1]
    .split('/');
  const [chosenCollection, setChosenCollection] = useState(() =>
    getInitialChosenCollection(structure, rootCollection, collection),
  );
  const { setCurrentArticle } = useContext(ArticlesContext);
  const chosenArticlePath = `${rootCollection}/${collection}/${article}/`;

  useEffect(() => {
    handleCollectionChange(`${rootCollection}/${collection}/`);

    if (extraSegments.length) {
      redirectToCollection(rootCollection, collection, article);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootCollection, collection]);

  useEffect(() => {
    const currentArticle = chosenCollection.articles.find(
      (article) => article.path === chosenArticlePath,
    );

    setCurrentArticle({ ...currentArticle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const redirectToCollection = (rootCollection = 'html', collection = 'root', article = '') => {
    const articlePath = article && article + '/';

    navigate(`/compendium/${rootCollection}/${collection}/${articlePath}`, { replace: true });
  };

  const handleCollectionChange = (path) => {
    const [newRootCollection, newCollection] = path.split('/');

    if (newCollection !== 'root') {
      triggerStructure(`${newRootCollection}/`, true);
    }

    if (chosenCollection.path === path) {
      return;
    }

    try {
      setChosenCollection(
        retrieveCollectionFromStructure(structure, newRootCollection, newCollection),
      );
    } catch (error) {
      const mapErrorToRootCollection = {
        'Unexisting root collection': 'html',
        'Unexisting collection': newRootCollection,
      };
      const fallbackRootCollection = mapErrorToRootCollection[error.message];

      redirectToCollection(fallbackRootCollection);
      setChosenCollection(
        retrieveCollectionFromStructure(structure, fallbackRootCollection, 'root'),
      );
    }
  };

  const triggerStructure = (path, value) => {
    setStructure((currentStructure) =>
      currentStructure.map((rootCollection) =>
        rootCollection.path !== path
          ? rootCollection
          : {
              ...rootCollection,
              isOpen: typeof value !== 'undefined' ? value : !rootCollection.isOpen,
            },
      ),
    );
  };

  return (
    <div className="md:flex md:flex-nowrap h-[100svh] md:h-screen">
      <div
        className={cn(
          'fixed top-0 left-0 z-10 md:static flex w-full md:w-auto h-full bg-white md:translate-x-0 transition-transform duration-300 ease-out',
          { 'translate-x-[-92vw]': !isMobileStructureOpen },
        )}
      >
        <CompendiumStructure
          structure={structure}
          triggerStructure={triggerStructure}
          onCollectionClick={handleCollectionChange}
          chosenCollectionPath={chosenCollection.path}
        />
        <ArticleList articles={chosenCollection.articles} chosenArticlePath={chosenArticlePath} />
      </div>
      <div id="article-container" className="md:grow h-full ml-[8vw] md:ml-0 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default CompendiumLayout;
